
  import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
  import vSelect from "vue-select";

  @Component({
    components: {
      vSelect,
    },
  })
  export default class FilterSelect extends Vue {
    @Prop({ default: () => [] }) options!: number[];
    @Prop({ default: () => "Maak een keuze..." }) placeholder!: string;

    localValue = "";

    @Emit("input")
    handleInput() {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string) {
      this.localValue = newValue;
    }
  }
